import styled, { css } from "styled-components";
import type { SpaceProps } from "styled-system";
import { space } from "styled-system";
import { getBreakpoint, getDirection } from "../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import * as Tooltip from "../../../../../common-deprecated/styles/v2/toyota/components/Tooltip";

export const Wrapper = styled.div`
    text-align: start;
`;

export const Header = styled.header``;

export const Title = styled.h3.attrs(() => ({
    className: "t-gamma-text",
}))`
    margin: ${theme.space[3]}px 0;
    padding: 0 ${theme.space[1]}px;
    font-size: 2rem;
    text-align: ${getDirection("left")};

    @media ${getBreakpoint("up", "lg")} {
        font-size: 2.8rem;
    }
`;

export const InnerCol = styled.div<{ isPdf?: boolean }>`
    width: 100%;
    padding: ${({ isPdf }) => (isPdf ? 0 : theme.space[3])}px;
`;

export const Label = styled.div.attrs(() => ({
    className: "t-milli-text",
}))`
    margin-bottom: ${theme.space[2]}px;
    text-align: ${getDirection("left")};
    color: ${theme.colors.grey4};
`;

type DescriptionType = { open: boolean; showReadMore: boolean; heightLimit: number; fullHeight: number };

export const Description = styled.span.attrs(() => ({
    className: "t-milli-text",
}))<DescriptionType>`
    margin-top: ${theme.space[2]}px;
    color: ${theme.colors.grey4};

    ${({ showReadMore, heightLimit }) =>
        showReadMore &&
        css`
            display: block;
            position: relative;
            max-height: ${heightLimit}px;
            overflow: hidden;
            transition: max-height 0.3s;

            &:after {
                content: "";
                position: absolute;
                background: linear-gradient(transparent, white);
                transition: opacity 0.3s;
                opacity: 1;
                height: 40px;
                bottom: 0;
                left: 0;
                right: 0;
            }
        `};
    ${({ open, fullHeight }) =>
        open &&
        // 20px padding just to be safe
        css`
            display: block;
            padding-bottom: ${theme.space[2]}px;
            max-height: ${fullHeight + 20}px !important;
            overflow: hidden;
            &:after {
                opacity: 0;
                display: none;
            }
        `};
`;

export const Value = styled.div.attrs(() => ({
    className: "t-base-text",
}))<{ isPdf?: boolean } & SpaceProps>`
    display: ${({ isPdf }) => (isPdf ? "block" : "flex")};
    position: relative;
    min-height: 25px;
    text-align: ${getDirection("left")};
    color: ${theme.colors.primaryBlack};
    ${space};

    span {
        font-family: ${theme.fonts.base};
        font-size: 1.3rem;
        color: ${theme.colors.grey4};
    }

    ${IconWrapper} {
        margin-${getDirection("right")}: ${theme.space[1]}px;
    }

    ${Tooltip.Wrapper} {
        width: 400px;
    }
`;

export const ReadMoreLess = styled.button.attrs(() => ({
    className: "t-milli-text",
}))`
    display: flex;
    background: transparent;
    border: none;
`;
