import React from "react";
import { CommonMonthlyPriceLabelKey } from "../../../../../common-deprecated/priceUtils";
import { useCompareUSCSelector } from "../../../redux/store";
import { CompareUSCLabel, useCompareUSCLabel } from "../../../utils/labels";
import DisclaimerIconWrapper from "../common/DisclaimerIconWrapper";
import { UsedCarCompareDisclaimerType } from "../../../utils/disclaimerUtils";
import CompareTable from "../../common/CompareTable";
import * as CompareEntry from "../styles/CompareEntry";
import { getAemMonthlyRateFormat } from "../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import type { UsedParsedSpecType } from "../../../utils/usedSpecsUtils";
import {
    formatMonthlyFinancePrice,
    formatUscCashPrice,
    showUscPrice,
} from "../../../../shared-logic/utils/uscPriceUtils";
import { useCommonLabel } from "../../../../../common-deprecated/utils/commonLabels";
import type { LoadedUsedCompareCarType } from "../../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import InlineDisclaimer from "./InlineDisclaimer";
import LinkTextDisclaimer from "../common/LinkTextDisclaimer";
import { showUscCompareMonthlyRates } from "../../../utils/uscCompareFinanceSettingUtils";
import type { UscFinanceInfoType } from "../../../../shared-logic/types/UscCommonTypes";
import type { TextVariantsType } from "../../../../shared-components/toyota/styles/atoms/Text";
import Text from "../../../../shared-components/toyota/styles/atoms/Text";
import type { UscColorType } from "../../../../shared-components/toyota/styles/Variables.types";

type UsedComparePriceType = {
    price: UsedParsedSpecType["values"]["price"];
};

const UsedPriceSpec = ({ price }: UsedComparePriceType): JSX.Element | null => {
    const cars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars as LoadedUsedCompareCarType[]);
    const settings = useCompareUSCSelector((state) => state.commonSettings);
    const compareUscSettings = useCompareUSCSelector((state) => state.compareUscSettings);
    const [exVatLabel, inclVatLabel] = useCommonLabel(["uscExclVatCashPriceFormat", "uscInclVatCashPriceFormat"]);
    const monthlyEnabled = showUscCompareMonthlyRates(settings, compareUscSettings);

    const monthlyPriceFormat = getAemMonthlyRateFormat(settings, AemFmComponent.CarFilterUsed, "used");

    const cashPriceLabel = useCompareUSCLabel("usedCompareSpecCashOnly");
    const useBoldTextVariant = useCompareUSCSelector((state) => state.compareUscSettings.financeLabelsUseBoldText);
    const textVariant: TextVariantsType = useBoldTextVariant ? "body-book" : "body-small-book";

    const colorVariant: UscColorType = useBoldTextVariant ? "primary-black" : "grey-7";

    if (!price) return null;

    return (
        <CompareTable
            items={price}
            columnChild={(item, index) => {
                // Hide the price of this car. This will only happen when at least one of the other cars of the compare is showing a price.
                // The complete row will be hidden in useUsedCarSpecs when not one car should show a price
                const showPrice = showUscPrice(item.sourceCode);

                if (!showPrice && index === 0) return null;

                const showMonthly = monthlyEnabled && item?.monthlyPrice;
                const {
                    formattedDiscount: { primaryPrice: primaryDiscountPrice, secondaryPrice: secondaryDiscountPrice },
                    formattedCash: { primaryPrice, secondaryPrice },
                    isPromotion,
                } = formatUscCashPrice(settings, item.cashPrice, exVatLabel, inclVatLabel);

                // Format monthly price with flexibility matrix.
                const { monthlyFinanceLabels, euriborFinanceLabels } = cars[index].financeInfo
                    ? formatMonthlyFinancePrice(
                          item.monthlyPrice,
                          settings,
                          cars[index].financeInfo as UscFinanceInfoType,
                          monthlyPriceFormat,
                      )
                    : { monthlyFinanceLabels: [], euriborFinanceLabels: [] };

                return (
                    <CompareEntry.Wrapper>
                        <CompareEntry.Title>
                            {showMonthly ? (
                                <>
                                    <CompareUSCLabel
                                        label="usedCompareSpecPrice"
                                        params={{
                                            cash: isPromotion ? primaryDiscountPrice : primaryPrice,
                                        }}
                                    />
                                    {secondaryPrice && (
                                        <CompareUSCLabel
                                            label="usedCompareSpecPrice"
                                            params={{
                                                cash: isPromotion ? secondaryDiscountPrice : secondaryPrice,
                                            }}
                                        />
                                    )}
                                </>
                            ) : (
                                cashPriceLabel
                            )}
                            <DisclaimerIconWrapper
                                type={UsedCarCompareDisclaimerType.Cash}
                                reference={cars[index].id}
                            />
                        </CompareEntry.Title>
                        <CompareEntry.ValueWrapper>
                            <CompareEntry.KeyValue>
                                {showMonthly ? (
                                    <>
                                        {monthlyFinanceLabels.map(({ key, value }) => {
                                            switch (key) {
                                                case CommonMonthlyPriceLabelKey.Price:
                                                    return (
                                                        <CompareEntry.Value key={key}>
                                                            <span dangerouslySetInnerHTML={{ __html: value }} />
                                                            <DisclaimerIconWrapper
                                                                type={UsedCarCompareDisclaimerType.Finance}
                                                                reference={cars[index].id}
                                                            />
                                                        </CompareEntry.Value>
                                                    );

                                                case CommonMonthlyPriceLabelKey.Disclaimer:
                                                    return (
                                                        <CompareEntry.LegalText key={key}>
                                                            <Text variant={textVariant} color={colorVariant}>
                                                                <LinkTextDisclaimer
                                                                    type={UsedCarCompareDisclaimerType.Finance}
                                                                    reference={cars[index].id}
                                                                />
                                                            </Text>
                                                        </CompareEntry.LegalText>
                                                    );

                                                default:
                                                    return (
                                                        <CompareEntry.LegalText key={key}>
                                                            <Text variant={textVariant} color={colorVariant}>
                                                                <span dangerouslySetInnerHTML={{ __html: value }} />
                                                            </Text>
                                                        </CompareEntry.LegalText>
                                                    );
                                            }
                                        })}

                                        {/* Euribor values are combined into a single label */}
                                        {euriborFinanceLabels && euriborFinanceLabels.length > 0 && (
                                            <CompareEntry.LegalText>
                                                <Text variant="body-small-book" color="grey-7">
                                                    {euriborFinanceLabels.map(({ value }) => (
                                                        <span key={value}>{value}</span>
                                                    ))}
                                                </Text>
                                            </CompareEntry.LegalText>
                                        )}
                                    </>
                                ) : (
                                    <CompareEntry.Value>
                                        <>
                                            {isPromotion ? (
                                                <>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: primaryDiscountPrice,
                                                        }}
                                                    />
                                                    {secondaryDiscountPrice && (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: secondaryDiscountPrice,
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <CompareEntry.DualPriceWrapper>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: primaryPrice,
                                                        }}
                                                    />
                                                    {secondaryPrice && (
                                                        <span dangerouslySetInnerHTML={{ __html: secondaryPrice }} />
                                                    )}
                                                </CompareEntry.DualPriceWrapper>
                                            )}
                                        </>
                                    </CompareEntry.Value>
                                )}
                                <InlineDisclaimer
                                    disclaimerType={UsedCarCompareDisclaimerType.Cash}
                                    reference={cars[index].id}
                                />
                                <InlineDisclaimer
                                    disclaimerType={UsedCarCompareDisclaimerType.Finance}
                                    reference={cars[index].id}
                                />
                            </CompareEntry.KeyValue>
                        </CompareEntry.ValueWrapper>
                    </CompareEntry.Wrapper>
                );
            }}
        />
    );
};

export default UsedPriceSpec;
