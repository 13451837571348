import React from "react";
import useMaxNumberToCompare from "../../../../shared-logic/features/compare/hooks/useMaxNumberToCompare";
import useUsedCarHeader from "../../hooks/useUsedCarHeader";
import CompareTable from "../common/CompareTable";
import { useCompareUSCSelector } from "../../redux/store";
import { CompareUSCLabel, useCompareUSCLabel } from "../../utils/labels";
import * as Button from "../../../../common-deprecated/styles/v2/toyota/components/Button";
import * as Text from "../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import * as Type from "../../../../common-deprecated/styles/v2/toyota/globals/Type";
import * as Styles from "./styles/CompareHeader";
import * as CompareItem from "./styles/CompareItem";
import { ToggleIconWrapper } from "../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import type { LoadedUsedCompareCarType } from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { SrOnly } from "../../../../common-deprecated/styles/v2/toyota/utilities/ScreenreaderOnly";
import DualCurrencyDisclaimer from "../../../shared-components/toyota/dual-currency-disclaimer/DualCurrencyDisclaimer";

type UsedCompareHeaderPropsType = {
    expanded: boolean;
    toggleExpanded: () => void;
};

const UsedCarHeader = (props: UsedCompareHeaderPropsType): JSX.Element => {
    const { expanded, toggleExpanded } = props;
    const cars = useCompareUSCSelector(
        ({ usedCarCompareExternalData }) => usedCarCompareExternalData.cars,
    ) as LoadedUsedCompareCarType[];

    const maxNumberToCompare = useMaxNumberToCompare();
    const titleLabel = useCompareUSCLabel("compareHeaderChooseUpToXCars", {
        maxNumberOfCars: maxNumberToCompare,
    });

    const { trackedToggleExpanded, trackedClearAll, trackedClearCar } = useUsedCarHeader(
        expanded,
        toggleExpanded,
        cars.length,
    );

    return (
        <Styles.Wrapper expanded={expanded}>
            <Styles.Actions>
                {(cars.length > 1 || expanded) && (
                    <Button.Primary type="button" light center m={0} onClick={trackedToggleExpanded}>
                        <Text.Button>
                            <CompareUSCLabel label={expanded ? "compareHeaderHide" : "compareHeaderTitle"} />
                        </Text.Button>
                        <ToggleIconWrapper ml={1} iconColor="primaryBlack" isDown={expanded} top={expanded ? "2px" : 0}>
                            <Icon variant="chevronUp" />
                        </ToggleIconWrapper>
                    </Button.Primary>
                )}
            </Styles.Actions>
            <Styles.ItemWrapper>
                <CompareTable
                    items={cars}
                    columnChild={(car, index) => (
                        <CompareItem.Border showCursorPointer={expanded} hideBorder={index + 1 === maxNumberToCompare}>
                            <Styles.MaskedPreloader show={car.loading} />
                            <CompareItem.Title hideBorder={index + 1 === maxNumberToCompare}>
                                <Type.Text headingType="milli" mb={0}>
                                    {car.product.brand.description}
                                </Type.Text>
                                <Type.Text headingType="base" styleType="headline" mb={0}>
                                    {car.product.model}
                                </Type.Text>
                                <CompareItem.Subtitle>{`${car.product.engine?.description || ""}${
                                    car.product.grade?.gradeDescription || ""
                                }`}</CompareItem.Subtitle>
                            </CompareItem.Title>
                            <CompareItem.IconHolder>
                                <CompareItem.CloseButton
                                    onClick={() => trackedClearCar(index, car.trackingProductName)}
                                >
                                    {expanded ? <Icon variant="chevronDown" /> : <Icon variant="close" />}

                                    <SrOnly>
                                        <CompareUSCLabel label="ariaRemoveFromCompare" />
                                    </SrOnly>
                                </CompareItem.CloseButton>
                            </CompareItem.IconHolder>
                        </CompareItem.Border>
                    )}
                    placeholder={() => (
                        <CompareItem.Border showCursorPointer={expanded}>
                            <CompareItem.Title center hideBorder>
                                {titleLabel}
                            </CompareItem.Title>
                        </CompareItem.Border>
                    )}
                />
            </Styles.ItemWrapper>
            <Styles.Actions align="right">
                <Styles.ButtonClearAll type="button" light onClick={trackedClearAll}>
                    <Text.Button>
                        <CompareUSCLabel label="compareHeaderClearAll" />
                    </Text.Button>
                    <Icon variant="close" />
                </Styles.ButtonClearAll>
            </Styles.Actions>
            <Styles.DisclaimerWrappers>
                <DualCurrencyDisclaimer variantOutline />
            </Styles.DisclaimerWrappers>
        </Styles.Wrapper>
    );
};

export default UsedCarHeader;
