import React from "react";
import ModalBaseView from "../../../../../common-deprecated/components/modals/aem/ModalBaseView";
import type { MultipleChoiceType } from "../../../../../common-deprecated/components/modals/aem/ModalActionViews";
import { MultipleChoice } from "../../../../../common-deprecated/components/modals/aem/ModalActionViews";
import useMaxNumberToCompare from "../../../../../shared-logic/features/compare/hooks/useMaxNumberToCompare";
import * as Modal from "../../../../../common-deprecated/styles/v2/toyota/globals/Modal";
import * as Text from "../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import * as Type from "../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import type { CompareUSCDispatchType } from "../../../redux/store";
import { useCompareUSCSelector } from "../../../redux/store";
import { usedCompareAddCars } from "../../../../shared-logic/redux/actions/UsedCarCompareExternalDataActions";
import type { ReplaceCarType } from "../../../../../shared-logic/features/compare/utils/modalConstants";
import { CompareUSCLabel, useCompareUSCLabel } from "../../../utils/labels";
import type { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";
import { useDispatch } from "../../../../../shared-logic/redux/store";

const ToyotaReplaceCarModal = (props: ModalViewType<ReplaceCarType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { usedCarToAdd } = modalSettings;

    const usedCars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars || []);

    const [loadingLabel, compareModalCancelButton] = useCompareUSCLabel([
        "compareBodyLoading",
        "compareModalCancelButton",
    ]);

    const dispatch = useDispatch<CompareUSCDispatchType>();
    const maxNumberToCompare = useMaxNumberToCompare();

    // We are injecting this label as HTML because there are 2 value fields in this single label
    let carToAddHTML = "";
    let options: MultipleChoiceType["list"] = [];
    if (usedCarToAdd) {
        carToAddHTML = `${usedCarToAdd.product.brand.description} ${usedCarToAdd.product.model}`;
        options = usedCars.map((car, index) => ({
            id: `${car.id}-${index}`,
            label:
                car.product.brand.description && car.product.model
                    ? `${car.product.brand.description} ${car.product.model}`
                    : loadingLabel,
            handler: (replaceIndex: number) => {
                dispatch(usedCompareAddCars([usedCarToAdd], replaceIndex));
                close();
            },
        }));
    }

    const label = useCompareUSCLabel("compareModalReplaceCar", {
        maxNumberToCompare: `<strong>${maxNumberToCompare}</strong>`,
        newCar: carToAddHTML,
    });

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="sm">
            <Modal.Header buttonAlignment="left">
                <Modal.Button type="button" onClick={close} lightBg hasText>
                    <IconWrapper>
                        <Icon variant="arrowLeft" />
                    </IconWrapper>
                    <Text.Button>
                        <CompareUSCLabel label="compareSelectBack" />
                    </Text.Button>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                <Type.Text headingType="base" dangerouslySetInnerHTML={{ __html: label }} />
            </Modal.Body>
            <MultipleChoice list={options} onCancel={close} cancelLabel={compareModalCancelButton} />
        </ModalBaseView>
    );
};

export default ToyotaReplaceCarModal;
