import styled from "styled-components";
import type { SpaceProps, DisplayProps } from "styled-system";
import { space, display } from "styled-system";
import { theme } from "../theme/toyotaTheme";

export const Hr = styled.hr<SpaceProps & DisplayProps>`
    display: block;
    width: 100%;
    color: ${theme.colors.grey2};
    border: 0;
    border-bottom: 1px solid ${theme.colors.grey2};
    ${display};
    ${space};
`;

export default Hr;
