import React from "react";
import CollapsibleList from "../list/CollapsibleList";
import UsedEquipmentLegend from "./UsedEquipmentLegend";
import { useCompareUSCSelector } from "../../../redux/store";
import type { CompareUSCLabelType } from "../../../utils/labels";
import { CompareUSCLabel } from "../../../utils/labels";
import * as CompareSection from "../styles/CompareSection";
import * as CompareSpecs from "../styles/CompareSpecs";
import type { EquipmentSpecNameIdType } from "../../../utils/compareTypes";
import useEnabledSpecSections from "../../../hooks/useEnabledSpecSections";

export type UsedCarSpecs = {
    isPdf?: boolean;
};

const filterEmptySpecValues = (specs: EquipmentSpecNameIdType[]): EquipmentSpecNameIdType[] => {
    return specs.filter((spec) => spec.values?.every((value) => !!value.value));
};

const UsedCarSpecs = ({ isPdf }: UsedCarSpecs): JSX.Element => {
    const equipment = useCompareUSCSelector((state) => state.usedCarCompare.parsedEquipment);
    const cultureName = useCompareUSCSelector((state) => state.commonSettings.culture.name);
    const labels = useCompareUSCSelector((state) => state.compareUscSettings.labels);
    const disabledCategories = useCompareUSCSelector((state) => state.compareUscSettings.disabledEquipmentCategories);
    const enabledParsedSpecs = useEnabledSpecSections();

    const filteredEquipment = equipment.filter(({ code }) => !disabledCategories.includes(code));

    return (
        <CompareSection.Wrapper>
            {!!enabledParsedSpecs.length && (
                <CompareSpecs.Title>
                    <CompareUSCLabel label="compareBodyCarSpecsTitle" />
                </CompareSpecs.Title>
            )}

            {enabledParsedSpecs.map((spec) => (
                <CollapsibleList
                    key={spec.category}
                    header={labels[spec.category as CompareUSCLabelType]}
                    items={filterEmptySpecValues(spec.specs)}
                    cultureName={cultureName}
                    showAllSpecs={!!isPdf}
                    itemNameIsLabelKey
                    showPrices
                    isPdf={isPdf}
                />
            ))}

            {!!filteredEquipment.length && <UsedEquipmentLegend />}

            {filteredEquipment.map((spec) => (
                <CollapsibleList
                    key={spec.code}
                    header={spec.name}
                    items={spec.items}
                    cultureName={cultureName}
                    showAllSpecs={!!isPdf}
                    isPdf={isPdf}
                />
            ))}
        </CompareSection.Wrapper>
    );
};

export default UsedCarSpecs;
