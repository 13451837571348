import type { ModalMapType } from "../../../../../common-deprecated/types/CommonTypes";
import ToyotaDeleteCarModal from "./DeleteCarModal";
import ToyotaReplaceCarModal from "./ReplaceCarModal";
import type { CompareV2ModalIdsType } from "../../../../../shared-logic/features/compare/utils/modalConstants";
import { MODAL_DELETE_CAR, MODAL_REPLACE_CAR } from "../../../../../shared-logic/features/compare/utils/modalConstants";
import IntegratedUscFormModal from "../../../../shared-components/toyota/floating-elements/modals/integrated-modal/IntegratedUscFormModal";
import { MODAL_USC_FORM } from "../../../../shared-logic/utils/integrated-modal/integratedModalConstants";
import type { SharedUscModalIdsType } from "../../../../shared-logic/utils/modalConstants";
import { MODAL_USC_INJECTION } from "../../../../shared-logic/utils/modalConstants";
import UscInjectionModal from "./UscInjectionModal";
import { uscToyotaModalMap } from "../../../../shared-components/toyota/floating-elements/modals/modals";

export const aemToyotaCompareUscModalMap: ModalMapType<CompareV2ModalIdsType & SharedUscModalIdsType> = {
    ...uscToyotaModalMap,
    [MODAL_DELETE_CAR]: ToyotaDeleteCarModal,
    [MODAL_REPLACE_CAR]: ToyotaReplaceCarModal,
    [MODAL_USC_FORM]: IntegratedUscFormModal,
    [MODAL_USC_INJECTION]: UscInjectionModal,
};
