import styled, { css, keyframes } from "styled-components";
import type { DisplayProps, WidthProps, FlexboxProps, BorderProps } from "styled-system";
import { display, width, flexbox, compose, border } from "styled-system";
import { getBreakpoint, getDirection } from "../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { TableCol } from "../../common/CompareTable";
import * as Button from "../../../../../common-deprecated/styles/v2/toyota/components/Button";
import * as Text from "../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import * as CompareItem from "./CompareItem";
import { EllipsisStyle } from "../../../../../common-deprecated/styles/v2/toyota/utilities/Ellipsis";

const pdfCss = css`
    display: block !important;
    position: relative !important;
    top: 0 !important;
    width: 100% !important;
    margin: 0 !important;
    padding-bottom: 50px;
    box-shadow: none !important;
    transform: none !important;
    overflow: visible !important;
`;

const overFlowAuto = keyframes`
  0% {
      overflow: initial;
  }
  99% {
      overflow: initial;
  }
  100% {
      overflow: auto;
  }
`;

export const Wrapper = styled.section<{ expanded?: boolean; isPdf?: boolean }>`
    display: flex;
    position: fixed;
    top: 100%;
    ${getDirection("left")}: 0;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    background: ${theme.colors.primaryWhite};
    box-shadow: 0 -4px 11px 0 rgba(0, 0, 0, 0.3);
    transform: translateY(${({ expanded }) => (expanded ? "-100%" : 0)});
    transition: transform 0.5s;
    overflow: initial;
    overscroll-behavior: contain;
    will-change: transform;
    z-index: ${theme.zIndex.popover};
    -webkit-overflow-scrolling: touch;

    ${TableCol} {
        &:last-of-type {
            ${CompareItem.IconHolder} {
                ${getDirection("right")}: -${theme.space[1]}px;
            }
        }
    }

    ${({ expanded }) =>
        expanded &&
        css`
            animation: ${overFlowAuto} 0.5s linear;
            overflow-y: hidden;
            overflow-x: hidden;
        `};
    ${({ isPdf }) => isPdf && pdfCss};
`;

export const PdfSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: ${theme.space[5]}px 0;

    /* Override CSS from foundation team */
    ${Button.Cta} {
        margin: 0 !important;
        padding-${getDirection("right")}: 0 !important;
        background: none !important;
    }
`;

export const Container = styled.div<{ isStandalone?: boolean }>`
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    @media (min-width: 1200px) {
        max-width: ${({ isStandalone }) => (isStandalone ? 980 : 780)}px;
    }
`;

export const Row = styled.div`
    padding: 0;
    @media ${getBreakpoint("only", "md")} {
        padding: 0 ${theme.space[1]}px;
    }
`;

const styles = compose(display, width, flexbox, border);
export const Col = styled.div<DisplayProps & WidthProps & FlexboxProps & BorderProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.space[3]}px;
    ${styles};
    @media ${getBreakpoint("up", "md")} {
        max-width: 245px;
    }
`;

export const Body = styled.div<{ isPdf?: boolean }>`
    width: 100%;
    padding: ${theme.space[3]}px;
    background-color: ${theme.colors.primaryWhite};
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;

    @media ${getBreakpoint("only", "md")} {
        margin-left: auto;
        margin-right: auto;
    }

    @media all and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
        /**
            Fix specific for IE11, to add some spacing above the body, else the car images are hidden after the compare header :(
        */
        position: relative;
        top: 100px;
    }

    ${({ isPdf }) =>
        isPdf &&
        css`
            position: static !important;
            top: 0 !important;
            margin-top: 30px !important;
            overflow: visible !important;
        `};
`;

export const CompareItems = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme.space[3]}px 0;

    ${Button.Primary},
    ${/* sc-selector */ Button.Outline},
    ${Button.Cta} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        &:not(:last-child) {
            margin-bottom: ${theme.space[2]}px;
        }
    }

    ${Button.Outline} {
        ${Text.Button} {
            ${EllipsisStyle};
            max-width: 100%;
            padding-left: ${theme.space[3]}px;
            padding-right: ${theme.space[3]}px;
        }
    }
`;
