import type { FC } from "react";
import React from "react";
import useIntegratedUsedCompare from "../../hooks/useIntegratedUsedCompare";
import UsedCompare from "./UsedCompare";

const IntegratedUsedCompare: FC = () => {
    const { fetching } = useIntegratedUsedCompare();

    return fetching ? null : <UsedCompare />;
};

export default IntegratedUsedCompare;
