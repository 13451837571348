import * as React from "react";
import type { CompareUSCLabelType } from "../../../utils/labels";
import { CompareUSCLabel } from "../../../utils/labels";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import * as CompareSpecs from "../styles/CompareSpecs";
import * as CompareLegend from "../styles/CompareLegend";

type IconType = "check" | "circle" | "dash" | "info" | "tag";

const legendItems: { id: string; name: CompareUSCLabelType; icon: IconType }[] = [
    { id: "standard", name: "compareBodyLegendStandard", icon: "check" },
    { id: "notapplicable", name: "compareBodyLegendNotApplicable", icon: "dash" },
];

export const getLegendIcon = (icon: IconType): JSX.Element => {
    switch (icon) {
        case "check":
            return (
                <IconWrapper iconColor="success">
                    <Icon variant="check" />
                </IconWrapper>
            );
        case "dash":
            return (
                <IconWrapper iconColor="grey4">
                    <Icon variant="minus" />
                </IconWrapper>
            );
        default:
            throw new Error("Unknown LegendIcon Type");
    }
};

const UsedEquipmentLegend = (): JSX.Element => {
    return (
        <CompareSpecs.Wrapper>
            <CompareSpecs.Title>
                <CompareUSCLabel label="compareBodyEquipment" />
            </CompareSpecs.Title>
            <CompareLegend.Wrapper>
                {legendItems.map((item) => (
                    <CompareLegend.Item key={`legend-${item.id}`}>
                        {getLegendIcon(item.icon)} <CompareUSCLabel label={item.name} />
                    </CompareLegend.Item>
                ))}
            </CompareLegend.Wrapper>
        </CompareSpecs.Wrapper>
    );
};

export default UsedEquipmentLegend;
