import styled from "styled-components";
import type { SpaceProps, BorderProps } from "styled-system";
import { space, border } from "styled-system";
import { ToggleIconWrapper } from "../globals/IconWrapper";
import { getDirection } from "../../../../themes/common";
import { theme } from "../theme/toyotaTheme";
import * as Table from "../../../../components/styles/Table";

export const Wrapper = styled.div`
    padding: 0;
`;

export const Header = styled.button<{ isPdf?: boolean }>`
    display: ${({ isPdf }) => (isPdf ? "block" : "flex")};
    align-items: center;
    width: 100%;
    padding: ${theme.space[3]}px 0;
    font-family: ${theme.fonts.regular};
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: ${getDirection("left")};
    border-bottom: 1px solid ${theme.colors.grey2};
    cursor: pointer;

    ${ToggleIconWrapper} {
        align-self: center;
        margin-${getDirection("left")}: auto;

        @media print {
            display: none;
        }
    }
`;

export const HeaderVisual = styled.span`
    display: flex;
    margin-right: ${theme.space[2]}px;
    color: ${theme.colors.primaryBlack};
`;

export const Body = styled.div<SpaceProps & BorderProps>`
    ${space}
    ${border}

    /* Specific style for alignment in compare */
    [role="cell"] {
        ${Table.InnerCol} {
            padding: ${theme.space[3]}px;
        }
        &:first-of-type {
            ${Table.InnerCol} {
                padding-${getDirection("left")}: 0;
            }
        }
    }
`;

export const Item = styled.div<SpaceProps & BorderProps>`
    padding-top: ${theme.space[3]}px;
    ${space}
    ${border}
`;
