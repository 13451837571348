import React, { useRef } from "react";
import FocusTrap from "focus-trap-react";
import useCompareToggle from "../../../../shared-logic/features/compare/hooks/useCompareToggle";
import UsedCarHeader from "./UsedCarHeader";
import UsedCarSpecs from "./specifications/UsedCarSpecs";
import UsedCompareDetails from "./highlight/UsedCompareDetails";
import UsedCtaContainer from "./UsedCtaContainer";
import { MAIN_NAV_CLASSNAME_TOYOTA } from "../../../../common-deprecated/constants";
import { useCompareUSCSelector } from "../../redux/store";
import UnsetZIndexHelper from "../../../../common-deprecated/components/UnsetZIndexHelper";
import PageDisclaimers from "./PageDisclaimers";
import * as Styles from "./styles/CompareStyles";
import ScrollIndicatorDown from "../../../../common-deprecated/components/scroll-indicator/ScrollIndicatorDown";
import { useBreakpoint } from "../../../../common-deprecated/themes/common";

const UsedCarCompareView = (): JSX.Element | null => {
    const cars = useCompareUSCSelector(({ usedCarCompareExternalData }) => usedCarCompareExternalData.cars);
    const standalone = useCompareUSCSelector(({ compareUscSettings }) => compareUscSettings.compareV2Standalone);
    const topOfCompareRef = useRef<HTMLElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const { show, expanded, toggleExpanded } = useCompareToggle({
        topOfCompareRef,
        bodyRef,
        hasCars: !!cars.length,
        standalone,
    });
    const isMobile = useBreakpoint("down", "md");

    return show ? (
        <FocusTrap
            active={expanded}
            focusTrapOptions={{
                tabbableOptions: { getShadowRoot: () => false },
                allowOutsideClick: true,
            }}
        >
            <Styles.Wrapper expanded={expanded}>
                <UnsetZIndexHelper enabled={expanded} selectors={[MAIN_NAV_CLASSNAME_TOYOTA]} />
                <UsedCarHeader expanded={expanded} toggleExpanded={toggleExpanded} />
                <Styles.Body ref={bodyRef}>
                    <Styles.Container role="table">
                        <span ref={topOfCompareRef} />
                        <UsedCompareDetails />
                        <UsedCtaContainer />
                        <UsedCarSpecs />
                        <UsedCtaContainer />
                        <PageDisclaimers />
                    </Styles.Container>
                </Styles.Body>
                {isMobile && <ScrollIndicatorDown viewportRef={bodyRef} recheckKey={String(expanded)} />}
            </Styles.Wrapper>
        </FocusTrap>
    ) : null;
};

export default UsedCarCompareView;
