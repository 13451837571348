import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { showAemMonthlyRates } from "../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import type { CompareUscSettingsType } from "../redux/reducers/CompareUscSettingsReducer";

export const showUscCompareMonthlyRates = (
    commonSettings: CommonSettingsType,
    compareUscSettings: CompareUscSettingsType,
): ReturnType<typeof showAemMonthlyRates> => {
    return (
        compareUscSettings.enableFinance && showAemMonthlyRates(commonSettings, AemFmComponent.CarFilterUsed, "used")
    );
};
