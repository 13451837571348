import styled, { css } from "styled-components";
import { getDirection } from "../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { Backdrop } from "../../../../../common-deprecated/styles/v2/toyota/components/Backdrop";
import * as Type from "../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import type { UscObjectFitType } from "../../../../shared-logic/types/UscCommonTypes";

export const Wrapper = styled.article`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: space-between;
    width: 100%;
    min-width: 150px;
    padding: 0 ${theme.space[2]}px;
`;

export const Border = styled(Wrapper)<{ showCursorPointer?: boolean; hideBorder?: boolean }>`
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 72px;
    border-${getDirection("right")}: ${({ hideBorder }) => `${hideBorder ? 0 : 1}px solid ${theme.colors.grey2}`};
    cursor: ${({ showCursorPointer }) => (showCursorPointer ? "pointer" : "default")};

    ${Backdrop} {
        z-index: ${theme.zIndex.fixed};
    }
`;

export const ImageWrapper = styled.div`
    display: inline-block;
    width: 100px;
    height: 47px;
    @supports (object-fit) {
        object-fit: contain;
        object-position: center;
    }
`;

export const Title = styled.div<{ hideBorder?: boolean; center?: boolean }>`
    width: 100%;
    padding-${getDirection("right")}: ${theme.space[3]}px;
    color: ${theme.colors.primaryWhite};
    overflow: hidden;

    ${Type.Text} {
        &:first-child {
            margin-${getDirection("right")}: ${theme.space[1]}px;
        }
    }

    ${Type.Strong} {
        color: ${theme.colors.primaryWhite};
    }
`;

export const CarTitle = styled.div`
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-overflow: ellipsis;
    color: ${theme.colors.grey1};
    white-space: nowrap;
    overflow: hidden;
`;

export const Subtitle = styled.div.attrs(() => ({
    className: "t-milli-text",
}))`
    margin: 0;
    color: ${theme.colors.primaryWhite};
    /* stylelint-disable */
    display: -webkit-box;
    max-width: 100%;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    /* stylelint-enable */
    hyphens: auto;
`;

export const EngineNameTitle = styled.h2.attrs(() => ({
    className: "t-milli-text",
}))`
    margin-bottom: 0;
    line-height: 2rem;
    text-overflow: ellipsis;
    color: ${theme.colors.grey4};
    white-space: nowrap;
    overflow: hidden;
`;

export const CloseButton = styled.button`
    color: ${theme.colors.primaryWhite};
`;

export const IconHolder = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    ${getDirection("right")}: ${theme.space[2]}px;
    align-items: center;
    width: 24px;
    transform: translateY(-50%);
    cursor: pointer;

    .icon {
        padding: 0;
        line-height: 1;
    }
`;

export const Placeholder = styled.div<{ objectFit: UscObjectFitType; isLoading?: boolean }>`
    position: relative;
    margin: 0;
    overflow: hidden;

    &::before {
        height: 0;
        padding-top: 56.25%; /* Aspect ratio 16:9 */
        content: "";
    }

    img {
        width: auto;
        max-height: 100%;
        object-fit: ${({ objectFit }) => objectFit};
    }

    .svg {
        width: 100%;
        height: 100%;
        max-height: 128px;
        opacity: 0.125;
    }

    svg,
    .icon {
        width: 100%;
        height: 100%;
        opacity: 0.125;
    }

    ${({ isLoading }) =>
        isLoading &&
        css`
            padding-top: 52%;
        `}
`;

export const PrevImage = styled.div<{ show?: boolean; transition?: string }>`
    transition: ${({ transition }) => transition};
    opacity: ${({ show }) => (show ? 1 : 0)};
`;
